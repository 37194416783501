import _ from 'lodash';

import {
  checkModelsCached,
  clearModelData,
  prefetchData,
  prefetchModelFiles,
} from 'src/lib/casrjs/src/casr/cache.js';
import { LocalRecogniserModelTypes } from '.';

export class LocalRecogniser extends EventTarget {
  modelStatus?: string;
  isReady: boolean = false;

  private _worker?: Worker;

  private _initDefered?: Deferred<void>;

  private _modelVersions: any;

  async init(modelLinks: any) {
    const modelVersions = Object.entries(modelLinks).map(([key, value]) => ({
      [key]: (value as any)['version'] || 1,
    }));
    if (this.isReady) {
      const sameModels = _.isEqual(modelVersions, this._modelVersions);
      if (sameModels) {
        return;
      }
    }

    this._setIsReady(false);

    this._setModelStatus('model_loading');

    try {
      console.log('Loading model files:', 'shared');
      await prefetchModelFiles('shared', modelLinks['shared']);

      for (const modelType of LocalRecogniserModelTypes) {
        const modelInfo = modelLinks[modelType];
        if (modelInfo) {
          //take first
          console.log('Loading model files:', modelType);
          await prefetchModelFiles(modelType, modelInfo);

          const modelmeta = await getModelmetaForModelInfo(modelInfo);
          const modelmetaBlob = new Blob([JSON.stringify(modelmeta)], {
            type: 'application/json',
          });
          const modelmetaBlobUrl = URL.createObjectURL(modelmetaBlob);
          await prefetchData('/modelmeta.json', modelmetaBlobUrl);
          URL.revokeObjectURL(modelmetaBlobUrl);
          break;
        }
      }
    } catch (error) {
      console.error('Failed to load casr models', error);
    }

    if (!LocalRecogniserModelTypes.some((t) => checkModelsCached(t))) {
      throw new Error('No recognition model files');
    }

    if (!checkModelsCached('shared')) {
      throw new Error('No shared files for model');
    }

    this._initDefered = createDeferred();
    if (!this._worker) {
      this._worker = new Worker(
        new URL('./local_recogniser_async_worker.ts', import.meta.url),
      );
      this._worker.onmessage = this._handleWorkerMessage.bind(this);
    }
    this._worker?.postMessage({ command: 'init', args: modelLinks });

    await this._initDefered.promise;
  }

  private _handleWorkerMessage(e: MessageEvent) {
    const { event } = e.data;
    console.log('worker message', event);

    if ('init_started' === event) {
      this._setIsReady(false);
      this._setModelStatus('model_loading');
    } else if ('init_finished' === event) {
      const { modelVersions } = e.data;
      this._modelVersions = modelVersions;
      this._setModelStatus('model_ready');
      this._setIsReady(true);
      this._initDefered?.resolve();
      this._initDefered = undefined;
    } else if ('init_error' === event) {
      const { message } = e.data;
      this._initDefered?.reject(new Error(message));
      this._initDefered = undefined;
    } else if ('decode' === event) {
      const { result } = e.data;
      this.dispatchEvent(new CustomEvent('decode', { detail: result }));
    }
  }

  cleanUp() {
    clearModelData();
    this._setModelStatus();
    this._setIsReady(false);

    this._modelVersions = undefined;

    this._worker?.terminate();
    this._worker = undefined;
  }

  feed(chunk: Array<number>) {
    this._worker?.postMessage({ command: 'feed', args: chunk });
  }

  stopFeed() {
    this._worker?.postMessage({ command: 'stop_feed' });
  }

  private _setModelStatus(status?: string) {
    this.modelStatus = status;
    this.dispatchEvent(
      new CustomEvent('change', { detail: { modelStatus: status } }),
    );
  }

  private _setIsReady(value: boolean) {
    this.isReady = value;
    this.dispatchEvent(new CustomEvent('change', { detail: { ready: value } }));
  }
}

type Deferred<T> = {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
};

function createDeferred<T>(): Deferred<T> {
  let deferred: Partial<Deferred<T>> = {};

  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred as Deferred<T>;
}

const urlToFilename = (url: string) => {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  return pathname.substring(pathname.lastIndexOf('/') + 1);
};

const getModelmetaForModelInfo = async (modelInfo: any) => {
  const modelmeta = await (await fetch('/modelmeta.json')).json();
  const filesToFind = ['encoder', 'decoder', 'joiner'];
  filesToFind.forEach((name) => {
    const link = _.find(modelInfo.links, (l: string) => l.includes(name));
    if (link) {
      const filename = urlToFilename(link);
      _.set(modelmeta, `${name}.model_basename`, filename);
    }
  });
  if (modelInfo.modelmeta) {
    _.merge(modelmeta, modelInfo.modelmeta);
  }
  return modelmeta;
};
