const cacheVersion = 1;
const cachePrefix = `casrjs`;
const cacheName = `${cachePrefix}-${cacheVersion}`;

const urlToCacheKey = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const key = pathname.substring(pathname.lastIndexOf('/'));
    return key;
}

export async function prefetchModelFiles(type, modelInfo) {
    const { version, links } = modelInfo;
    const cacheStorage = await caches.open(cacheName);
    const localModelKey = `${cacheName}-${type}`;

    const localModelInfoString = localStorage.getItem(localModelKey)
    const isNotCached = !localModelInfoString;
    let isOutdated = false;
    if (localModelInfoString) {
        const localModelInfo = JSON.parse(localModelInfoString);
        if (localModelInfo.version !== version) {
            isOutdated = true;
            localModelInfo.keys.forEach(key => {
                cacheStorage.delete(key);
            });
            localStorage.removeItem(localModelKey);
        }
    }

    if (isNotCached || isOutdated) {
        await Promise.all(links.map(url => prefetchData(urlToCacheKey(url), url)));

        localStorage.setItem(localModelKey, 
            JSON.stringify({
                type,
                version,
                keys: links.map(urlToCacheKey),
            })
        );
    }
}

export function clearModelData() {
    for (var key in localStorage){
        if (key.startsWith(cachePrefix)) {
            localStorage.removeItem(key);
        }
     }
    deleteOldCaches();
}

export function checkModelsCached(type) {
    //setting to local storage is done after downloads, so if there is entry -- files are downloaded
    const localModelKey = `${cacheName}-${type}`;
    return !!localStorage.getItem(localModelKey);
}

export async function prefetchData(key, url) {
    const cacheStorage = await caches.open(cacheName);
    const response = await fetch(url);
    if (response.ok) {
        await cacheStorage.put(key, response);
    } else {
        throw new Error(`Failed to fetch data from ${url}. Status: ${response.status}`)
    }
}

export async function getDataAsText(url) {
    let cachedData = await getCachedDataAsText(cacheName, url);

    if (cachedData) {
        console.log("Retrieved cached data:", url);
        return cachedData;
    }

    console.log("Fetching fresh data:", url);

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(url);
    cachedData = await getCachedDataAsText(cacheName, url);
    await deleteOldCaches(cacheName);

    return cachedData;
}

export async function getDataAsJson(url) {
    let cachedData = await getCachedDataAsJson(cacheName, url);

    if (cachedData) {
        console.log("Retrieved cached data:", url);
        return cachedData;
    }

    console.log("Fetching fresh data:", url);

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(url);
    cachedData = await getCachedDataAsJson(cacheName, url);
    await deleteOldCaches(cacheName);

    return cachedData;
}

export async function getData(url) {
    let cachedData = await getCachedData(cacheName, url);

    if (cachedData) {
        console.log("Retrieved cached data:", url);
        return cachedData;
    }

    console.log("Fetching fresh data:", url);

    const cacheStorage = await caches.open(cacheName);
    await cacheStorage.add(url);
    cachedData = await getCachedData(cacheName, url);
    await deleteOldCaches(cacheName);

    return cachedData;
}

// Get data from the cache.
async function getCachedDataResponse(cacheName, url) {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    return cachedResponse;
}

async function getCachedData(cacheName, url) {
    const cachedResponse = await getCachedDataResponse(cacheName, url);
    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.arrayBuffer();
}

async function getCachedDataAsText(cacheName, url) {
    const cachedResponse = await getCachedDataResponse(cacheName, url);
    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.text();
}

async function getCachedDataAsJson(cacheName, url) {
    const cachedResponse = await getCachedDataResponse(cacheName, url);
    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    return await cachedResponse.json();
}

// Delete any old caches to respect user's disk space.
export async function deleteOldCaches(currentCache) {
    const keys = await caches.keys();

    for (const key of keys) {
        const isOurCache = key.startsWith(cachePrefix);
        if (currentCache === key || !isOurCache) {
            continue;
        }
        console.log('Deleting cache:', key)
        caches.delete(key);
    }
}
