import { createContext, useRef, useEffect, ReactNode, useState } from 'react';
import _ from 'lodash';

import useAuth from 'src/hooks/useAuth';
import usePrevious from 'src/hooks/usePrevious';
import { useReduxStorage } from 'src/redux/store';
import {
  LocalRecogniser,
  ExecutionProvider,
  LocalRecogniserResult,
} from 'src/lib/local_recogniser';
import { clientApi } from 'src/api/clientApi';

type PartialRecognition = {
  full_text?: string;
  text?: string;
  unstable_text?: string;
};

type Recognition = {
  date: Date;
  text?: string;
};

interface LocalRecognitionContextValue {
  isAvailable: boolean;
  isInitializing: boolean;
  canStream: boolean;
  recognition?: Recognition;
  partial_recognition?: PartialRecognition;
  error?: string;
  isConnected: boolean; //stub
  actions: {
    init: (ep: ExecutionProvider) => Promise<void>;
    stream: (chunk: Array<number>) => void;
    resetRecognition: () => void;

    //stubs
    disconnect: (force: boolean) => void;
    resetAlbCookies: () => void;
    clearSocket: () => void;
    startStreamProcessing: () => void;
    stopStreamProcessing: () => void;
    setRecognitionMode: () => void;
    setStreamingVadMinSilence: () => void;
    setFilterProfanities: () => void;
    streamCompressedAudio: (blob: Blob) => void;
  };
}

const initialContextValue: LocalRecognitionContextValue = {
  isAvailable: false,
  isInitializing: false,
  canStream: false,
  recognition: undefined,
  partial_recognition: undefined,
  error: undefined,
  isConnected: true,
  actions: {
    init: async (ep: ExecutionProvider) => {},
    stream: (chunk: Array<number>) => {},
    resetRecognition: () => {},

    disconnect: () => {},
    resetAlbCookies: () => {},
    clearSocket: () => {},
    startStreamProcessing: () => {},
    stopStreamProcessing: () => {},
    setRecognitionMode: () => {},
    setStreamingVadMinSilence: () => {},
    setFilterProfanities: () => {},
    streamCompressedAudio: (blob: Blob) => {},
  },
};

export const LocalRecognitionContext =
  createContext<LocalRecognitionContextValue>(initialContextValue);

interface LocalRecognitionContextProviderProps {
  children: ReactNode;
}

export const LocalRecognitionContextProvider: React.FC<
  LocalRecognitionContextProviderProps
> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const modelsInfo = useReduxStorage('models').state.data || [];

  const recogniserRef = useRef<LocalRecogniser>();

  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [modelStatus, setModelStatus] = useState<string>();
  const [error, setError] = useState<string>();
  const [recognition, setRecognition] = useState<Recognition>();
  const [partialRecognition, setPartialRecognition] =
    useState<PartialRecognition>();

  //TODO: check with user settings
  const isAvailable = true;

  const onRecogniserChange = (e: CustomEventInit) => {
    if (e.detail.hasOwnProperty('ready')) {
      setIsReady(e.detail.ready);
    } else if (e.detail.hasOwnProperty('modelStatus')) {
      setModelStatus(e.detail.modelStatus);
    }
  };

  const onDecodeResult = (e: CustomEventInit) => {
    const result: LocalRecogniserResult = e.detail;

    if ('start_of_speech' === result.event) {
      //TODO: create new file
    } else if ('speech_chunk' === result.event) {
      const showResult = result.asr.text.length !== 0;
      if (showResult) {
        setPartialRecognition({
          full_text: result.asr.text,
        });
        setRecognition(undefined);
      }
    } else if ('end_of_speech' === result.event) {
      setPartialRecognition(undefined);
      setRecognition({ date: new Date(), text: result.asr.text });

      //TODO: implement audio upload to Gringotts-CASR
    }
  };

  const resetRecognition = () => {
    setPartialRecognition(undefined);
    setRecognition(undefined);
  };

  useEffect(() => {
    const recogniser = new LocalRecogniser();
    recogniser.addEventListener('change', onRecogniserChange);
    recogniser.addEventListener('decode', onDecodeResult);
    recogniserRef.current = recogniser;
    return () => {
      recogniser.removeEventListener('change', onRecogniserChange);
      recogniser.addEventListener('decode', onDecodeResult);
    };
  }, []);

  // Disconnect on logout
  useEffect(() => {
    const isLoggedOut = !isAuthenticated && prevIsAuthenticated;
    if (isLoggedOut) {
      recogniserRef.current?.cleanUp();

      setIsInitializing(false);
      setIsReady(false);
      setError(undefined);
      setModelStatus(undefined);
      setRecognition(undefined);
      setPartialRecognition(undefined);
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  const init = async () => {
    setIsInitializing(true);
    try {
      const modelLinks = await clientApi.getCasrModelLinks();
      await recogniserRef.current?.init(modelLinks);
      setError(undefined);
    } catch (error: any) {
      setError(error.message);
      setTimeout(() => {
        setError(undefined);
      }, 100);
    }
    setIsInitializing(false);
  };

  const stream = (chunk: Array<number>) => {
    recogniserRef.current?.feed(chunk);
  };

  return (
    <LocalRecognitionContext.Provider
      value={{
        isAvailable,
        isInitializing,
        canStream: isReady,
        isConnected: true,
        recognition,
        partial_recognition: partialRecognition,
        error,
        actions: {
          init,
          stream,
          resetRecognition,

          disconnect: () => {},
          resetAlbCookies: () => {},
          clearSocket: () => {},
          startStreamProcessing: () => {},
          stopStreamProcessing: () => {},
          setRecognitionMode: () => {},
          setStreamingVadMinSilence: () => {},
          setFilterProfanities: () => {},
          streamCompressedAudio: (blob: Blob) => {},
        },
      }}
    >
      {children}
    </LocalRecognitionContext.Provider>
  );
};
